import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getInstructionsSuccess,
    getInstructionsFailure,
    getInstructions,
} from './actions';
import { getInstructionsRequest } from './api';

function* getSaga() {
    try {
        const result: any[] = yield call(getInstructionsRequest, 3);
        yield put(getInstructionsSuccess(result));
    } catch (error) {
        console.log(error);
        yield put(getInstructionsFailure(error));
    }
}

export function* instructionsWatcher() {
    yield takeEvery(getInstructions.toString(), getSaga);
}
