import fetch from 'isomorphic-fetch';

export const getPostsAllNumberRequest = () => {
    const url =
        'https://ryadom-media.deareditor.ru/wp-json/ryadom/v2/posts-count';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
