import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { NewsOneState } from './types';
import { getNewsOne, getNewsOneIdle, getNewsOneSuccess } from './actions';

export const initialState: NewsOneState = {
    status: 'IDLE',
    newsOne: null,
};

const status = handleActions<StateStatus>(
    {
        [getNewsOne]: () => 'LOADING',
        [getNewsOneSuccess]: () => 'SUCCESS',
        [getNewsOneIdle]: () => 'IDLE',
    },
    initialState.status
);

const newsOne = handleActions(
    {
        [getNewsOneSuccess]: (state, action) => {
            return action.payload.newsOne;
        },
    },
    initialState.newsOne
);

export const newsOneReducer = combineReducers({
    status,
    newsOne,
});
