import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getNews, getNewsFailure, getNewsSuccess } from './actions';
import { getNewsRequest } from './api';

function* getSaga() {
    try {
        const newsResult: any[] = yield call(getNewsRequest);

        yield put(
            getNewsSuccess({
                news: newsResult,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getNewsFailure(error));
    }
}

export function* newsWatcher() {
    yield takeEvery(getNews.toString(), getSaga);
}
