import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getInstruction,
    getInstructionSuccess,
    getInstructionFailure,
    getInstructionIdle,
} from './actions';
import { StateStatus } from 'types/models';
import { InstructionState } from './types';

export const initialState: InstructionState = {
    status: 'IDLE',
    instruction: null,
};

const status = handleActions<StateStatus>(
    {
        [getInstruction]: () => 'LOADING',
        [getInstructionSuccess]: () => 'SUCCESS',
        [getInstructionIdle]: () => 'IDLE',
    },
    initialState.status
);

const instruction = handleActions(
    {
        [getInstructionSuccess]: (state, action) => action.payload,
    },
    initialState.instruction
);

export const instructionReducer = combineReducers({
    status,
    instruction,
});
