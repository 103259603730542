import { fork, all } from 'redux-saga/effects';

import { routerSaga } from './ducks/router/saga';
import { getInitdataWatcher } from './ducks/app/saga';
import { homepageWatcher, postsWatcher } from './ducks/homepage/saga';
import { newsOneWatcher } from './ducks/newsOne/saga';
import { newsWatcher } from './ducks/news/saga';
import { postWatcher } from './ducks/post/saga';
import { instructionWatcher } from './ducks/instruction/saga';
import { instructionsWatcher } from './ducks/instructions/saga';
import { rubricsWatcher } from './ducks/Rubrics/saga';

export default function* rootSaga() {
    yield all([
        fork(getInitdataWatcher),
        fork(homepageWatcher),
        fork(rubricsWatcher),
        fork(newsWatcher),
        fork(newsOneWatcher),
        fork(postWatcher),
        fork(instructionWatcher),
        fork(instructionsWatcher),
        fork(routerSaga),
        fork(postsWatcher),
        // fork(newsfeedsSaga),
    ]);
}
