import fetch from 'isomorphic-fetch';

export const getNewsRequest = (count: number) => {
    const url = `https://ryadom-media.deareditor.ru/wp-json/wp/v2/news${
        !!count ? '?per_page=' + count : ''
    }`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
