import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Header, Footer } from 'components';
import routes from 'routes';
import './App.css';
import { getInitdata } from 'store/ducks/app/actions';

function App() {
    const location = useLocation();
    const path = location.pathname;
    const dispatch = useDispatch();
    const statusInitData = useSelector(
        (state: { initdata: { status: string } }) => state.initdata.status
    );

    useEffect(() => {
        if (statusInitData === 'IDLE') {
            dispatch(getInitdata());
        }
    }, []);

    return (
        <div
            className={cn(
                'wrapper',
                path === '/' && '_main',
                path === '/about' && '_about',
                path === '/article' && '_article',
                path === '/search' && '_search',
                path === '/news' && '_news',
                path === '/rubrics' && '_rubrics',
                path === '/article-variety' && '_article-variety'
            )}
        >
            <Header />
            <Switch>
                {routes.map(({ fetchData, ...routeProps }) => (
                    <Route key={routeProps.path} {...routeProps} />
                ))}
            </Switch>
            <Footer />
        </div>
    );
}

const Component = hot(App);

export { Component as App };
