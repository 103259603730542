import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getCitesRequest,
    getEcologyRequest,
    getInstructionsRequest,
    getNewsRequest,
    getPostsRequest,
} from './api'; // getMaterialsLoadMoreRequest

import {
    getHomepage,
    getHomepageFailure,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
} from './actions';

import { Post } from 'types/models';
import { getPostsAllNumberRequest } from './api/getPostsAllNumberRequest';

function* getSaga() {
    try {
        const [news, ecology, cites]: any[] = yield all([
            call(getNewsRequest, 3),
            call(getEcologyRequest, 9, 3),
            call(getCitesRequest, 8, 7),
        ]);

        yield put(
            getHomepageSuccess({
                news,
                ecology,
                cites,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getHomepageFailure(error));
    }
}

function* getSagaPosts(action: { payload: { offset: number } }) {
    try {
        const { payload } = action;
        const [posts, postsAllNumber]: any[] = yield all([
            call(getPostsRequest, payload.offset),
            call(getPostsAllNumberRequest),
        ]);

        yield put(getPostsSuccess({ posts, postsAllNumber }));
    } catch (error) {
        console.log(error);
        yield put(getPostsFailure(error));
    }
}

export function* homepageWatcher() {
    yield takeEvery(getHomepage.toString(), getSaga);
}

export function* postsWatcher() {
    yield takeEvery(getPosts.toString(), getSagaPosts);
}

// import { put, takeLatest, call } from 'redux-saga/effects';

// import * as actions from './actions';
// import * as types from './types';
// import * as service from './service';

// function* fetchHomepage() {
//     try {
//         const data = yield call(service.fetchHomepage);

//         yield put(actions.fetchHomepageSuccess(data));
//     } catch (error) {
//         yield put(actions.fetchHomepageError(error.message));
//     }
// }

// export function* homepageSaga() {
//     yield takeLatest(types.FETCH_HOMEPAGE, fetchHomepage);
// }
