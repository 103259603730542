import fetch from 'isomorphic-fetch';

export const getTagsRequest = () => {
    const url = 'https://ryadom-media.deareditor.ru/wp-json/wp/v2/tags';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
