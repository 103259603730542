import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getHomepage,
    getHomepageIdle,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsIdle,
    getPostsClear,
} from './actions';
import { HomepageState } from './types';
import { Post, StateStatus } from 'types/models';

export const initialState: HomepageState = {
    status: 'IDLE',
    statusPosts: 'IDLE',
    posts: [],
    postsAllNumber: 0,
    news: [],
    ecology: [],
    cites: [],
    // articles: [],
    // quizes: [],
    // videos: [],
    // materialsLoadMorePaginare: null,
};

const status = handleActions<StateStatus>(
    {
        [getHomepage]: () => 'LOADING',
        [getHomepageSuccess]: () => 'SUCCESS',
        [getHomepageIdle]: () => 'IDLE',
    },
    initialState.status
);

const news = handleActions(
    {
        [getHomepageSuccess]: (state, action) => action.payload.news,
    },
    initialState.news
);

const ecology = handleActions(
    {
        [getHomepageSuccess]: (state, action) => action.payload.ecology,
    },
    initialState.ecology
);

const cites = handleActions(
    {
        [getHomepageSuccess]: (state, action) => action.payload.cites,
    },
    initialState.cites
);

const statusPosts = handleActions<StateStatus>(
    {
        [getPosts]: () => 'LOADING',
        [getPostsSuccess]: () => 'SUCCESS',
        [getPostsIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

const posts = handleActions(
    {
        [getPostsSuccess]: (state, action: { payload: { posts: Post[] } }) => [
            ...state,
            ...action.payload.posts,
        ],
    },
    initialState.posts
);

const postsAllNumber = handleActions(
    {
        [getPostsSuccess]: (
            state,
            action: { payload: { postsAllNumber: number } }
        ) => action.payload.postsAllNumber,
    },
    initialState.postsAllNumber
);

export const homepage = combineReducers({
    status,
    posts,
    statusPosts,
    news,
    ecology,
    cites,
    postsAllNumber,
    // topMaterials,
    // articles,
    // quizes,
    // videos,
    // statusMaterialsLoadMore,
    // materialsLoadMore,
    // materialsLoadMorePaginare,
});
