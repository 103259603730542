import loadable from '@loadable/component';
// import { fetchAbout } from 'store/ducks/about/actions';
// import { fetchArticle } from 'store/ducks/article/actions';
// import { getNewsfeeds } from 'store/ducks/newsfeeds/actions';
// import { Router, Switch, Route } from 'react-router-dom';
// import { fetchHomepage } from 'store/ducks/homepage/actions';
// import { fetchNews } from 'store/ducks/newsOne/actions';
// import { getNews } from './components/News/actions';
// import { fetchShoes } from 'store/ducks/shoes/actions';
import { getHomepage } from 'store/ducks/homepage/actions';
import { RouterFetchDataArgs } from 'types';
import { getPosts } from './store/ducks/homepage/actions';
import { getInitdata } from './store/ducks/app/actions';
import { getInstructions } from './store/ducks/instructions/actions';
import { useParams } from 'react-router-dom';
import { getPost, getPostIdle } from './store/ducks/post/actions';
import { getNews } from './store/ducks/news/actions';
import { getNewsOne } from './store/ducks/newsOne/actions';
import { getInstruction } from './store/ducks/instruction/actions';
import { getRubrics } from 'store/ducks/Rubrics/actions';
const HomePage = loadable(() => import('./pages/Home/Home'));
const NotFoundPage = loadable(() => import('./pages/404/404'));
const About = loadable(() => import('./pages/About/About'));
const Article = loadable(() => import('./pages/Article/Article'));
const ArticleInstruction = loadable(() =>
    import('./pages/ArticleInstruction/ArticleInstruction')
);
const News = loadable(() => import('./pages/News/News'));
const Rubrics = loadable(() => import('./pages/Rubrics/Rubrics'));
const Search = loadable(() => import('./pages/Search/Search'));
const ArticleVariety = loadable(() =>
    import('./pages/ArticleVariety/ArticleVariety')
);

/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */

export default [
    {
        path: '/',
        component: HomePage,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getPosts({ offset: 0 }));
            dispatch(getInstructions());
            dispatch(getHomepage());
            dispatch(getInitdata());
        },
    },
    {
        path: '/about',
        component: About,
        exact: true,
    },
    {
        path: '/news/:slug',
        component: Article,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getNewsOne(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '/instructions/:slug',
        component: ArticleInstruction,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInstruction(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '/news',
        component: News,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getNews());
            dispatch(getInitdata());
        },
    },
    {
        path: '/posts/:slug',
        component: Rubrics,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getRubrics(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '/search',
        component: Search,
        exact: true,
        // fetchData({ dispatch }: RouterFetchDataArgs) {
        //     dispatch(fetchAbout());
        // },
    },
    {
        path: '/posts/:category/:slug',
        component: ArticleVariety,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getPost(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '/tags/:tagSlug/:slug',
        component: ArticleVariety,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getPost(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '*',
        component: NotFoundPage,
        exact: true,
    },
];
