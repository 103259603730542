import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getInitdata, getInitdataSuccess, getInitdataIdle } from './actions';
import { StateStatus } from './types';
import { IPostShortInfoDto, Post } from '../../../types/models';
import { useSelector } from 'react-redux';

export const initialState: {
    status: string | any;
    categories: any;
    tags: any;
} = {
    status: 'IDLE',
    categories: {},
    tags: {},
};

const status = handleActions<StateStatus>(
    {
        [getInitdata]: () => 'LOADING',
        [getInitdataSuccess]: () => 'SUCCESS',
        [getInitdataIdle]: () => 'IDLE',
    },
    initialState.status
);

const categories = handleActions(
    {
        [getInitdataSuccess]: (state, action) =>
            action.payload.categoriesObject,
    },
    initialState.categories
);

const tags = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.tagsObject,
    },
    initialState.tags
);

// get category
export const getCategories = (posts: IPostShortInfoDto[]) => (
    state: any
): any[] => {
    const { categories } = state.initdata.categories;
    if (!categories) return [];

    const postReduced = posts.reduce((arr: any[], post: IPostShortInfoDto) => {
        const newPost = postMapDtoCategory(post, categories);

        arr.push({ ...post, categories: newPost });
        return arr;
    }, []);

    return postReduced;
};

export const postMapDtoCategory = (
    post: IPostShortInfoDto,
    categories: any
) => {
    return post.categories.map((category: number) => {
        const findCategory = categories.find((c: any) => category === c.id);
        return { id: findCategory.id, name: findCategory.name };
    });
};

export const initdata = combineReducers({
    status,
    categories,
    tags,
});
