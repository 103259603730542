import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getRubrics,
    getRubricsSuccess,
    getRubricsFailure,
    getRubricsIdle,
} from './actions';
import { StateStatus, Post } from 'types/models';
import { RubricsState } from './types';

export const initialState: RubricsState = {
    status: 'IDLE',
    rubrics: null,
    title: '',
    postsAllNumber: 0,
};

const status = handleActions<StateStatus>(
    {
        [getRubrics]: () => 'LOADING',
        [getRubricsSuccess]: () => 'SUCCESS',
        [getRubricsIdle]: () => 'IDLE',
    },
    initialState.status
);

const rubrics = handleActions<Post[]>(
    {
        [getRubricsSuccess]: (state, action: { payload: any }) =>
            action.payload.rubrics,
    },
    initialState.rubrics
);

const postsAllNumber = handleActions<number>(
    {
        [getRubricsSuccess]: (state, action: { payload: any }) =>
            action.payload.postsAllNumber,
    },
    initialState.postsAllNumber
);
const title = handleActions<string>(
    {
        [getRubricsSuccess]: (state, action: { payload: any }) =>
            action.payload.title,
    },
    initialState.title
);

export const rubricsReducer = combineReducers({
    status,
    rubrics,
    postsAllNumber,
    title,
});
