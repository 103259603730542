import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import './Header.css';

export function Header() {
    const { pathname } = useLocation();
    const [menu, setMenu] = useState(false);

    const closeHandlerMenu = () => {
        setMenu(prevState => !prevState);
    };

    const MenuLinks = [
        { path: '/posts/kejsy', name: 'Кейсы' },
        { path: '/posts/idei', name: 'Идеи' },
        { path: '/posts/sovety', name: 'Советы' },
        { path: '/news', name: 'Новости' },
        { path: '/about', name: 'О проекте' },
    ];

    useEffect(() => {
        const firstScreen = document.querySelector('.first-screen');
        const scrollLogo = document.querySelector('.scroll-logo');
        scrollLogo.classList.remove('_active');

        const eventFunction = () => {
            const windowScrollY = window.pageYOffset;
            if (firstScreen) {
                const firstScreenScrollY = firstScreen.clientHeight;
                if (windowScrollY >= firstScreenScrollY) {
                    scrollLogo.classList.add('_active');
                } else {
                    scrollLogo.classList.remove('_active');
                }
            }
        };

        if (pathname === '/') {
            window.addEventListener('scroll', eventFunction);
        }
        return () => {
            if (pathname === '/') {
                window.removeEventListener('scroll', eventFunction);
            }
        };
    }, [pathname]);

    return (
        <>
            <header className="header">
                <div className="header__container">
                    <Link to="/" className="logo logo-ibg header__logo">
                        <img src="/img/logo.svg" alt="logo" />
                    </Link>
                    <Link to="/" className="logo-icon logo-ibg header__logo">
                        <img src="/img/logo2.svg" alt="logo" />
                    </Link>
                    <div className="header__scroll-logo">
                        <Link to="/" className="scroll-logo croll-logo-ibg">
                            <img src="/img/logo.svg" alt="logo" />
                        </Link>
                        <Link to="/" className="logo-icon logo-ibg _scroll">
                            <img src="/img/logo2.svg" alt="logo" />
                        </Link>
                    </div>
                    <h1 className="header__title">Медиа Группы «Эталон»</h1>
                </div>
            </header>
            <button
                type="button"
                className="header__menu-btn icon-menu"
                onClick={() => setMenu(true)}
            >
                Меню
            </button>
            <div
                id="popup"
                aria-hidden="true"
                className={cn('popup', menu && 'popup_show popup--list')}
            >
                <div className="popup__wrapper">
                    <div className="popup__content">
                        <div className="menu">
                            <div className="menu__header">
                                <button
                                    data-close
                                    type="button"
                                    className="popup__close"
                                    onClick={closeHandlerMenu}
                                >
                                    Закрыть
                                </button>
                                <Link
                                    to="/"
                                    onClick={closeHandlerMenu}
                                    className="logo logo-ibg header__logo"
                                >
                                    <img src="/img/logo.svg" alt="logo" />
                                </Link>
                                <Link
                                    to="/"
                                    onClick={closeHandlerMenu}
                                    className="logo-icon logo-icon--scroll logo-ibg header__logo"
                                >
                                    <img src="/img/logo2.svg" alt="logo" />
                                </Link>
                            </div>
                            <div className="menu__body">
                                {/* <form className="menu-search menu__search">
                                    <input
                                        type="text"
                                        className="menu-search__input"
                                        placeholder="Что вы ищете?"
                                    />
                                    <button className="menu-search__btn">
                                        Поиск
                                    </button>
                                </form> */}
                                <ul className="menu__list">
                                    {MenuLinks.map((link, index) => {
                                        return (
                                            <li
                                                className="menu__item"
                                                key={index}
                                            >
                                                <Link
                                                    to={link.path}
                                                    className="menu__link"
                                                    onClick={closeHandlerMenu}
                                                >
                                                    {link.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="menu__footer">
                                <ul className="social menu__social">
                                    <li className="social__item">
                                        <a
                                            href="https://instagram.com/ryadom.media"
                                            target="_blank"
                                            className="social__link social__link--instagram"
                                        ></a>
                                    </li>
                                    <li className="social__item">
                                        <a
                                            href="https://www.facebook.com/ryadom.media/"
                                            target="_blank"
                                            className="social__link social__link--facebook"
                                        ></a>
                                    </li>
                                    {/* <li className="social__item">
                                        <a
                                            href="#"
                                            className="social__link social__link--telegram"
                                        ></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
