import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getInstructionSuccess,
    getInstructionFailure,
    getInstruction,
} from './actions';
import { getInstructionRequest } from './api';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const result: any[] = yield call(getInstructionRequest, payload);

        yield put(getInstructionSuccess(result[0]));
    } catch (error) {
        console.log(error);
        yield put(getInstructionFailure(error));
    }
}

export function* instructionWatcher() {
    yield takeEvery(getInstruction.toString(), getSaga);
}
