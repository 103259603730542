import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';
import { getPostRequest } from './api';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const result: any[] = yield call(getPostRequest, payload);

        yield put(getPostSuccess(result[0]));
    } catch (error) {
        console.log(error);
        yield put(getPostFailure(error));
    }
}

export function* postWatcher() {
    yield takeEvery(getPost.toString(), getSaga);
}
