import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { NewsState } from './types';
import { getNews, getNewsIdle, getNewsSuccess } from './actions';

export const initialState: NewsState = {
    status: 'IDLE',
    news: [],
};

const status = handleActions<StateStatus>(
    {
        [getNews]: () => 'LOADING',
        [getNewsSuccess]: () => 'SUCCESS',
        [getNewsIdle]: () => 'IDLE',
    },
    initialState.status
);

const news = handleActions(
    {
        [getNewsSuccess]: (state, action) => {
            return action.payload.news;
        },
    },
    initialState.news
);

export const newsReducer = combineReducers({
    status,
    news,
});
