import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';

// const b = bem.with('footer');

export function Footer() {
    const { pathname } = useLocation();

    return (
        <>
            <footer className="footer">
                <div className="footer__container">
                    <Link
                        to={pathname}
                        className="footer_up"
                        data-goto=".header"
                    >
                        наверх
                    </Link>
                    <Link to="/about" className="footer__link">
                        о проекте
                    </Link>
                    <ul className="social footer__social">
                        <li className="social__item">
                            <a
                                href="https://instagram.com/ryadom.media"
                                target="_blank"
                                className="social__link social__link--instagram"
                            ></a>
                        </li>
                        <li className="social__item">
                            <a
                                href="https://www.facebook.com/ryadom.media/"
                                target="_blank"
                                className="social__link social__link--facebook"
                            ></a>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    );
}
