import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getCategoriesRequest, getTagsRequest } from './api';

import { getInitdata, getInitdataFailure, getInitdataSuccess } from './actions';

function* getSaga() {
    try {
        const [categories, tags]: any[] = yield all([
            call(getCategoriesRequest),
            call(getTagsRequest),
        ]);

        let categoriesObject = {};
        if (categories && categories.length > 0) {
            categories.forEach((category: { id: number }) => {
                categoriesObject = {
                    ...categoriesObject,
                    [category.id]: category,
                };
            });
            categoriesObject = {
                ...categoriesObject,
                all: { id: 0, title: 'Лента' },
            };
        }

        let tagsObject = {};
        if (tags && tags.length > 0) {
            tags.forEach((tag: { id: number }) => {
                tagsObject = {
                    ...tagsObject,
                    [tag.id]: tag,
                };
            });
            tagsObject = {
                ...tagsObject,
                all: { id: 0, title: 'Лента' },
            };
        }
        yield put(
            getInitdataSuccess({
                categoriesObject,
                tagsObject,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getInitdataFailure(error));
    }
}

export function* getInitdataWatcher() {
    yield takeEvery(getInitdata.toString(), getSaga);
}
