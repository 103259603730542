import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getInstructions,
    getInstructionsSuccess,
    getInstructionsFailure,
    getInstructionsIdle,
} from './actions';
import { StateStatus } from 'types/models';
import { InstructionsState } from './types';

export const initialState: InstructionsState = {
    status: 'IDLE',
    instructions: null,
};

const status = handleActions<StateStatus>(
    {
        [getInstructions]: () => 'LOADING',
        [getInstructionsSuccess]: () => 'SUCCESS',
        [getInstructionsIdle]: () => 'IDLE',
    },
    initialState.status
);

const instructions = handleActions(
    {
        [getInstructionsSuccess]: (state, action) => action.payload,
    },
    initialState.instructions
);

export const instructionsReducer = combineReducers({
    status,
    instructions,
});
