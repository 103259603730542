import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

// import homepage from './ducks/homepage/reducer';
import { newsOneReducer } from './ducks/newsOne/reducer';
import about from './ducks/about/reducer';
import { State } from 'types';
import { homepage } from './ducks/homepage/reducer';
import { initdata } from './ducks/app/reducer';
import { newsReducer } from './ducks/news/reducer';
import { postReducer } from './ducks/post/reducer';
import { instructionReducer } from './ducks/instruction/reducer';
import { instructionsReducer } from './ducks/instructions/reducer';
import { rubricsReducer } from './ducks/Rubrics/reducer';

export default (history: History) =>
    combineReducers<State>({
        initdata,
        homepage,
        news: newsReducer,
        post: postReducer,
        rubrics: rubricsReducer,
        newsOne: newsOneReducer,
        instruction: instructionReducer,
        instructions: instructionsReducer,
        about,
        router: connectRouter(history),
    });
