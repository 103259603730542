import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getRubrics, getRubricsSuccess, getRubricsFailure } from './actions';
import { getRubricsRequest } from './api';

function* getSaga(action: any) {
    try {
        const { payload }: { payload: any } = action;

        const result: { [x: string]: any } = yield call(
            getRubricsRequest,
            payload
        );

        yield put(
            getRubricsSuccess({
                title: result.title,
                rubrics: result.posts,
                postsAllNumber: result.found_posts,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getRubricsFailure(error));
    }
}

export function* rubricsWatcher() {
    yield takeEvery(getRubrics.toString(), getSaga);
}
