import fetch from 'isomorphic-fetch';

export const getPostsRequest = (offset: number) => {
    let perPage = 3;
    if (offset === 0) {
        perPage = 4;
    }
    const url = `https://ryadom-media.deareditor.ru/wp-json/wp/v2/posts?per_page=${perPage}&offset=${offset}`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
